import React from 'react';
import moment from 'moment';
import { IconButton, Typography, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useTaskModal } from '../../contexts/TaskModalContext';
import { formatRecurrenceRule } from '../habits/HabitCard';

const TaskItem = ({ task, onEditClick }) => {
    const { openTaskModal } = useTaskModal();

    const handleEditClick = (e) => {
        e.stopPropagation();
        openTaskModal(task);
        if (onEditClick) {
            onEditClick(task);
        }
    };

    const formatDate = (date) => {
        const momentDate = moment(date);
        const today = moment().startOf('day');
        const tomorrow = moment().add(1, 'days').startOf('day');

        if (momentDate.isSame(today, 'day')) {
            return 'Today';
        } else if (momentDate.isSame(tomorrow, 'day')) {
            return 'Tomorrow';
        } else if (momentDate.isSame(today, 'week')) {
            return momentDate.format('dddd');
        } else {
            return momentDate.format('MMM D, YYYY');
        }
    };

    const isHabit = task.recurrenceRule && task.recurrenceRule.toUpperCase() !== 'ONCE';

    const formatRecurrence = (rule) => {
        if (rule.toUpperCase() === 'ONCE') {
            return 'Once';
        }
        try {
            return formatRecurrenceRule(rule);
        } catch (error) {
            console.error('Error formatting recurrence rule:', error);
            return 'Invalid recurrence';
        }
    };

    return (
        <Box className="relative p-2 mb-2 bg-white rounded shadow">
            <Typography variant="subtitle1" component="h3" className="pr-8 font-semibold">
                {task.title}
            </Typography>
            <Box display="flex" flexDirection="column" gap={0.5}>
                {task.startDate && (
                    <Typography variant="body2" color="text.secondary">
                        <Box component="span" fontWeight="medium" display="inline-block" width="60px">
                            Start:
                        </Box>
                        {formatDate(task.startDate)}
                    </Typography>
                )}
                {!isHabit && task.dueDate && (
                    <Typography variant="body2" color="text.secondary">
                        <Box component="span" fontWeight="medium" display="inline-block" width="60px">
                            Due:
                        </Box>
                        {formatDate(task.dueDate)}
                    </Typography>
                )}
                {task.recurrenceRule && (
                    <Typography variant="body2" color="text.secondary">
                        <Box component="span" fontWeight="medium" display="inline-block" width="60px">
                            Repeat:
                        </Box>
                        {formatRecurrence(task.recurrenceRule)}
                    </Typography>
                )}
            </Box>
            <IconButton
                onClick={handleEditClick}
                sx={{
                    position: 'absolute',
                    top: 4,
                    right: 4,
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    },
                }}
            >
                <EditIcon fontSize="small" />
            </IconButton>
        </Box>
    );
};

export default TaskItem;
