import React from 'react';
import { Card, CardContent, Typography, Box, Link, IconButton } from '@mui/material';
import { format, parseISO, isToday, isTomorrow, isThisWeek, addDays } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { RRule } from 'rrule';
import EditIcon from '@mui/icons-material/Edit';
import { useTaskModal } from '../../contexts/TaskModalContext';

function getDuration(task) {
    if (task.duration === -1 || task.duration === '-1') {
        switch (task.priority) {
            case 'CRITICAL':
                return '30 minutes';
            case 'OPPORTUNITY_NOW':
                return '1 hour';
            case 'OVER_THE_HORIZON':
                return '3 hours';
            case 'PARKING_LOT':
                return '4 hours';
            default:
                return '15 minutes';
        }
    }

    if (typeof task.duration === 'string') {
        // Handle cases like "2h", "1h", and "30m" directly
        if (task.duration.endsWith('h')) {
            const hours = parseInt(task.duration);
            return hours === 1 ? '1 hour' : `${hours} hours`;
        }
        if (task.duration.endsWith('m')) {
            const minutes = parseInt(task.duration);
            return minutes === 1 ? '1 minute' : `${minutes} minutes`;
        }

        // Parse the duration string for more complex cases
        const durationRegex = /^PT?(\d+H)?(\d+M)?$/i;
        const match = task.duration.match(durationRegex);
        if (match) {
            const hours = match[1] ? parseInt(match[1]) : 0;
            const minutes = match[2] ? parseInt(match[2]) : 0;
            if (hours > 0 && minutes > 0) {
                return `${hours}h ${minutes}m`;
            } else if (hours > 0) {
                return hours === 1 ? '1 hour' : `${hours} hours`;
            } else if (minutes > 0) {
                return minutes === 1 ? '1 minute' : `${minutes} minutes`;
            }
        }
    }

    console.log('Duration:', task.duration);
    return 'Invalid duration';
}

export function getNextOccurrence(task) {
    if (task.futureEvents && task.futureEvents.length > 0) {
        return task.futureEvents.reduce((earliest, event) =>
            new Date(event.start) < new Date(earliest.start) ? event : earliest
        );
    }
    return null;
}

export function formatNextOccurrence(nextOccurrence) {
    if (!nextOccurrence || !nextOccurrence.start) {
        return 'Not scheduled';
    }
    try {
        const date = parseISO(nextOccurrence.start);
        const now = new Date();

        if (isToday(date)) {
            return `Today at ${format(date, 'h:mma')}`;
        } else if (isTomorrow(date)) {
            return `Tomorrow at ${format(date, 'h:mma')}`;
        } else if (isThisWeek(date, { weekStartsOn: 1 })) {
            return format(date, "EEEE 'at' h:mma");
        } else {
            return format(date, "MMM d 'at' h:mma");
        }
    } catch (error) {
        console.error('Error formatting next occurrence:', error);
        return 'Invalid date';
    }
}

function getPriorityColor(priority) {
    switch (priority) {
        case 'CRITICAL':
            return '#0C803D';
        case 'OPPORTUNITY_NOW':
            return '#107CC4';
        case 'OVER_THE_HORIZON':
            return '#F9913B';
        case 'PARKING_LOT':
            return '#F04F23';
        default:
            return '#9e9e9e';
    }
}

export function formatRecurrenceRule(rule) {
    if (!rule) return 'Not set';

    const rrule = RRule.fromString(rule);
    const freq = rrule.options.freq;
    const interval = rrule.options.interval || 1;
    const byweekday = rrule.options.byweekday;

    switch (freq) {
        case RRule.DAILY:
            return interval === 1 ? 'Every day' : `Every ${interval} days`;
        case RRule.WEEKLY:
            if (byweekday && byweekday.length > 0) {
                const days = byweekday
                    .map((day) => ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][day])
                    .join(', ');
                return interval === 1 ? `Every week on ${days}` : `Every ${interval} weeks on ${days}`;
            }
            return interval === 1 ? 'Every week' : `Every ${interval} weeks`;
        case RRule.MONTHLY:
            return interval === 1 ? 'Every month' : `Every ${interval} months`;
        case RRule.YEARLY:
            return interval === 1 ? 'Every year' : `Every ${interval} years`;
        default:
            return rule;
    }
}

function HabitCard({ task }) {
    const navigate = useNavigate();
    const nextOccurrence = getNextOccurrence(task);
    const priorityColor = getPriorityColor(task.priority);
    const { openTaskModal } = useTaskModal();

    const handleCardClick = () => {
        navigate(`/habits/${task.id}`);
    };

    const handleEditClick = (e) => {
        e.stopPropagation();
        openTaskModal(task);
    };

    const isScheduled = task.isScheduled;

    return (
        <Card
            sx={{
                maxWidth: 345,
                borderRadius: 2,
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                '&:hover': {
                    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                    cursor: 'pointer',
                },
                borderLeft: `4px solid ${priorityColor}`,
                position: 'relative',
            }}
            onClick={handleCardClick}
        >
            <IconButton
                sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    zIndex: 1,
                }}
                onClick={handleEditClick}
            >
                <EditIcon />
            </IconButton>
            <CardContent>
                <Typography variant="h6" component="h2" gutterBottom>
                    {task.title}
                </Typography>
                <Box display="flex" flexDirection="column" gap={1}>
                    <Typography variant="body2" color="text.secondary">
                        <Box component="span" fontWeight="bold" display="inline-block" width="80px">
                            Duration:
                        </Box>
                        {getDuration(task)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        <Box component="span" fontWeight="bold" display="inline-block" width="80px">
                            Repeat:
                        </Box>
                        {formatRecurrenceRule(task.recurrenceRule)}
                    </Typography>
                </Box>
                <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="body2" color="text.secondary">
                        Next: {isScheduled ? formatNextOccurrence(nextOccurrence) : 'Not scheduled'}
                    </Typography>
                    {isScheduled && nextOccurrence && (
                        <Link
                            href={`/calendar?view=timeGridWeek&date=${format(parseISO(nextOccurrence.start), 'yyyy-MM-dd')}`}
                            color="primary"
                            onClick={(e) => {
                                e.preventDefault();
                                handleOpenClick(e);
                            }}
                        >
                            Open
                        </Link>
                    )}
                </Box>
            </CardContent>
        </Card>
    );
}

export default HabitCard;
export { getDuration, getPriorityColor };
