import React, { useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import DayRowTask from './DayRowTask';
import sortTasksAndEvents from './sortTasksAndEvents';
import { useTasks } from '../../providers/TasksProvider';
import { useSettings } from '../../atoms/SettingsAtom';

export const DayRow = ({
    day,
    openPriorityPanel,
    isPlanner,
    filteredPrettyEvents,
    selectedFilter,
    selectedCollection,
    tasks,
}) => {
    const taskRefs = useRef({});

    const { selectedTask } = useTasks();
    const { settings, updateSetting } = useSettings();

    const memoizedSelectedTask = useMemo(() => selectedTask, [selectedTask]);

    useEffect(() => {
        if (memoizedSelectedTask) {
            const scrollToTask = async () => {
                if (!settings.showFuture && memoizedSelectedTask.startDate > new Date()) {
                    updateSetting('showFuture', true);
                    await new Promise((resolve) => setTimeout(resolve, 0));
                }

                if (taskRefs.current[memoizedSelectedTask.id]) {
                    taskRefs.current[memoizedSelectedTask.id].scrollIntoView({
                        behavior: 'smooth',
                        block: 'nearest',
                        inline: 'start',
                    });
                }
            };

            scrollToTask();
        }
    }, [memoizedSelectedTask, memoizedSelectedTask?.priority, settings.showFuture, updateSetting]);

    const sortedEvents = useMemo(() => {
        if (filteredPrettyEvents?.[day]) {
            let events = filteredPrettyEvents[day];

            // Only keep events that match the provided tasks and are not parent recurring tasks
            events = events.filter((event) => {
                const matchingTask = tasks.find(
                    (task) =>
                        task.id === event.id &&
                        (!task.recurrenceRule || task.recurrenceRule.toUpperCase() == 'ONCE' || task.isRecurringChild)
                );
                return !!matchingTask;
            });

            const sorted = sortTasksAndEvents({ [day]: events }, day);
            return sorted;
        }
        return [];
    }, [filteredPrettyEvents, day, tasks, selectedFilter, selectedCollection]);

    return (
        <div>
            {sortedEvents.map((event) => (
                <DayRowTask
                    key={event.id}
                    task={event}
                    openPriorityPanel={openPriorityPanel}
                    isPlanner={isPlanner}
                    ref={(el) => (taskRefs.current[event.id] = el)}
                />
            ))}
        </div>
    );
};

DayRow.propTypes = {
    day: PropTypes.string.isRequired,
    openPriorityPanel: PropTypes.func.isRequired,
    isPlanner: PropTypes.bool.isRequired,
    filteredPrettyEvents: PropTypes.object,
    selectedFilter: PropTypes.string,
    selectedCollection: PropTypes.string,
    tasks: PropTypes.array.isRequired,
};

export default React.memo(DayRow);
