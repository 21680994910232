import React, { useMemo } from 'react';
import { Grid, Typography, Container } from '@mui/material';
import HabitCard from './HabitCard';
import { useTasks } from '../../providers/TasksProvider';
import { useCalendarEvents } from '../../contexts/CalendarEventsContext';
import { useCustomer } from '../../providers/CustomerProvider';
import { isAfter, parseISO } from 'date-fns';
import CalendarLinkBanner from './CalendarLinkBanner';

function safeExtractTaskInfo(task) {
    return {
        id: task.id,
        title: task.title,
        recurrenceRule: task.recurrenceRule,
        type: task.type,
        priority: task.priority,
        duration: task.duration,
        idealTime: task.idealTime,
        isCompleted: task.isCompleted,
    };
}

function Habits() {
    const { tasks } = useTasks();
    const { calendarEvents } = useCalendarEvents();
    const { customer } = useCustomer();

    const showCalendarLinkBanner = !customer?.accounts || customer?.accounts?.length === 0;

    const recurringTasks = useMemo(() => {
        if (!tasks || !calendarEvents) return [];

        const now = new Date();

        const filteredTasks = tasks.filter((task) => {
            const isRecurring = task.recurrenceRule && task.recurrenceRule.toUpperCase() !== 'ONCE';
            const isParent = task.type !== 'RecurringTask';
            return isRecurring && isParent;
        });

        return filteredTasks.map((task) => {
            const children = tasks.filter(
                (childTask) => childTask.type === 'RecurringTask' && childTask.parentTask?.id === task.id
            );

            const taskEvents = calendarEvents.filter(
                (event) => event.title === task.title || children.some((child) => child.title === event.title)
            );

            const futureEvents = taskEvents.filter((event) => isAfter(parseISO(event.start), now));

            return {
                ...safeExtractTaskInfo(task),
                children: children.map(safeExtractTaskInfo),
                isScheduled: futureEvents.length > 0,
                futureEvents,
            };
        });
    }, [tasks, calendarEvents]);

    return (
        <Container maxWidth="lg">
            {showCalendarLinkBanner && <CalendarLinkBanner />}
            <Typography variant="h4" component="h1" gutterBottom>
                Habits
            </Typography>
            <Grid container spacing={3}>
                {recurringTasks.map((task) => (
                    <Grid item xs={12} sm={6} md={4} key={task.id}>
                        <HabitCard task={task} />
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}

export default Habits;
