// Libraries
import React, { useEffect, useState, useMemo, useCallback, memo } from 'react';
import { Capacitor } from '@capacitor/core';

import ReactGA from 'react-ga4';

// Material UI
import { createTheme, ThemeProvider } from '@mui/material/styles';

//Local
import { useSettings } from '../../atoms/SettingsAtom';

import { useCurrentPicture } from '../../contexts/CurrentPictureContext';

import UpcomingEvents from '../calendar/UpcomingEvents';
import Briefing from './Briefing';

import { useTasks } from '../../providers/TasksProvider';

import HomeLoading from '../HomeLoading';

// Hooks

import { useIsLandscapeAndLargeScreen } from '../../hooks/useIsLandscapeAndLargeScreen';
import { usePlanning } from '../../hooks/usePlanning';

// Style
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const FullScreenBackground = memo(styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-image: ${(props) => (props.showBackground ? `url(${props.backgroundImage})` : 'none')};
    background-color: #f0f0f0;
    background-size: cover;
    background-position: center;
    transition: opacity 0.5s ease-in-out;
    opacity: ${(props) => (props.isLoaded ? 1 : 0)};
    z-index: -1;
`);

const ContentWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    z-index: 1;
`;

const BackgroundDiv = styled.div`
    background-image: ${(props) => (props.showBackground ? `url(${props.backgroundImage})` : 'none')};
    background-color: #f0f0f0;
    background-size: cover;
    background-position: center;
    transition: opacity 0.2s ease-in-out;
    opacity: ${(props) => (props.isLoaded ? 1 : 0)};
`;

const AnimatedDiv = styled.div`
    animation: ${fadeIn} 0.2s ease-in-out forwards;
`;
// Define a custom hook to determine whether the device is in landscape mode and has a large screen.

const MemoizedUpcomingEvents = memo(UpcomingEvents);
const MemoizedBriefing = memo(Briefing);

const Home = memo(() => {
    const { settings } = useSettings();

    const { tasks, isLoading: isTasksLoading, updateFilter } = useTasks();
    const { plan, isPlanning, scheduleAll, unScheduleAll, kickTheCan } = usePlanning();
    const [isBackgroundLoaded, setIsBackgroundLoaded] = useState(false);
    const [backgroundImage, setBackgroundImage] = useState(null);
    const { currentPicture, isLoading: isCurrentPictureLoading } = useCurrentPicture();
    const isLoading = isTasksLoading || isCurrentPictureLoading;

    useEffect(() => {
        ReactGA.initialize('G-HC3CNRJ6WD', {});
        ReactGA.send({ hitType: 'pageview', page: '/home', title: 'Home' });

        // Set the filter to 'ALL_TASKS' when the Home component mounts
        updateFilter('ALL_TASKS');
    }, []);

    const theme = useMemo(
        () =>
            createTheme({
                mixins: {
                    toolbar: {
                        minHeight: 'env(safe-area-inset-top)',
                    },
                },
                components: {
                    MuiModal: {
                        styleOverrides: {
                            root: {
                                top: 'env(safe-area-inset-top)',
                            },
                        },
                    },
                },
                palette: {
                    primary: {
                        main: '#107CC4',
                    },
                    secondary: {
                        main: '#f44336',
                    },
                },
            }),
        []
    );

    const isLandscapeAndLargeScreen = useIsLandscapeAndLargeScreen();
    const [isMobile] = useState(Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'ios');

    useEffect(() => {
        if (currentPicture && !isMobile) {
            const img = new Image();
            img.src = currentPicture.pictureUrl;
            img.onload = () => {
                setIsBackgroundLoaded(true);
                setBackgroundImage(currentPicture.pictureUrl);
            };
        } else {
            setIsBackgroundLoaded(false);
            setBackgroundImage(null);
        }
    }, [currentPicture, isMobile]);

    const invertColor = useCallback((hex) => {
        if (hex.indexOf('#') === 0) {
            hex = hex.slice(1);
        }
        // convert 3-digit hex to 6-digits.
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        if (hex.length !== 6) {
            throw new Error('Invalid HEX color.');
        }
        // invert color components
        var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
            g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
            b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
        // pad each with zeros and return
        return '#' + padZero(r) + padZero(g) + padZero(b);
    }, []);

    function padZero(str, len) {
        len = len || 2;
        var zeros = new Array(len).join('0');
        return (zeros + str).slice(-len);
    }

    const invertedColor = useMemo(() => {
        return currentPicture?.color !== undefined ? invertColor(currentPicture.color) : 'white';
    }, [currentPicture?.color, invertColor]);

    if (isLoading) {
        return <HomeLoading />;
    }
    return (
        <>
            <FullScreenBackground
                showBackground={settings.showBackground}
                backgroundImage={backgroundImage}
                isLoaded={isBackgroundLoaded}
            />
            {!Capacitor.isNativePlatform() && isLandscapeAndLargeScreen ? (
                <ContentWrapper>
                    <ThemeProvider theme={theme}>
                        <div
                            id="innerhome"
                            className="overflow-visible px-10 py-8 w-full h-full max-h-full"
                            style={{ minHeight: '100vh' }}
                        >
                            <div className="flex justify-between">
                                <div className="pb-0 mb-0 ml-0 w-2/5 h-1/4">
                                    <div className="p-0 pl-3 bg-white rounded-lg opacity-95 border-1 border-darkgray custom-shadow">
                                        <AnimatedDiv>
                                            <MemoizedBriefing isPlanner={false} />
                                        </AnimatedDiv>
                                        <br />
                                    </div>
                                </div>
                                <div className="w-1/12"></div>
                                <div className="sticky top-0 right-20 mr-14 w-1/3 h-1/4 align-top">
                                    <div className="p-1 w-full bg-white rounded-lg border-2 opacity-95 border-darkgray custom-shadow">
                                        <AnimatedDiv>
                                            <MemoizedUpcomingEvents />
                                        </AnimatedDiv>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {settings.showBackground && backgroundImage && isBackgroundLoaded && (
                            <div
                                className="fixed right-0 bottom-0 mr-16 mb-16 text-xl text-white transform -translate-x-1/2 -translate-y-1/2"
                                style={{ color: invertedColor }}
                            >
                                <a
                                    className="underline"
                                    href={backgroundImage}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Photo
                                </a>
                                <span className="ml-2">by</span>
                                <a
                                    href={`${currentPicture.photographerProfileLink}?utm_source=Mind Your Now&utm_medium=referral`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="ml-2 underline hover:text-gray-600"
                                >
                                    {currentPicture.photographer}
                                </a>
                                <span className="ml-2">on</span>
                                <a
                                    href="https://unsplash.com/?utm_source=Mind Your Now&utm_medium=referral"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="ml-2 underline hover:text-gray-600"
                                >
                                    Unsplash
                                </a>
                            </div>
                        )}
                    </ThemeProvider>
                </ContentWrapper>
            ) : (
                <div className="flex-1 max-h-full shadow-md drop-shadow-2xl">
                    <div className="p-2 bg-white rounded-lg opacity-95 border-1 border-darkgray">
                        <MemoizedBriefing isPlanner={false} />
                    </div>
                </div>
            )}
        </>
    );
});

export default Home;
