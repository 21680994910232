import React from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

export const CalendarErrorModal = ({ isOpen, onClose, onRetry }) => {
    if (!isOpen) return null;

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="flex fixed inset-0 z-50 justify-center items-center bg-black bg-opacity-50"
        >
            <motion.div
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 50, opacity: 0 }}
                className="p-6 w-full max-w-md bg-white rounded-lg shadow-xl"
            >
                <div className="text-center">
                    <FontAwesomeIcon icon={faExclamationTriangle} className="mb-4 text-5xl text-yellow-500" />
                    <h2 className="mb-4 text-2xl font-bold text-gray-800">Oops! We Need a Bit More Access</h2>
                    <p className="mb-6 text-gray-600">
                        To make the most of Mind Your Now, we need a few more permissions from Google Calendar. This
                        helps us seamlessly integrate your schedule and tasks.
                    </p>
                    <div className="flex justify-center items-center mb-6">
                        <FontAwesomeIcon icon={faCalendarAlt} className="mr-2 text-2xl text-blue-500" />
                        <span className="font-semibold text-blue-700">Google Calendar</span>
                    </div>
                    <button
                        onClick={onRetry}
                        className="px-6 py-2 mb-4 font-semibold text-white bg-blue-500 rounded-full transition duration-300 hover:bg-blue-600"
                    >
                        Grant Permissions
                    </button>
                    <p className="text-sm text-gray-500">
                        Don't worry, you can always manage these permissions later in your Google account settings.
                    </p>
                </div>
            </motion.div>
        </motion.div>
    );
};
