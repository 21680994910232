import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useTasks } from '../../providers/TasksProvider';
import { useSettings } from '../../atoms/SettingsAtom';
import TaskItem from './TaskItem';
import { Avatar, Box, Typography } from '@mui/material';
import { startOfDay, isToday, isFuture, parseISO } from 'date-fns';

import criticalImage from '/images/noun-exclamation-green.svg';
import overTheHorizonImage from '/images/noun-horizon-orange.svg';
import opportunityNowImage from '/images/noun-opportunity-blue.svg';
import parkingLotImage from '/images/noun-parking-lot-red.svg';

// Suppress the specific warning
const originalError = console.error;
console.error = (...args) => {
    if (
        args[0].includes('Warning: Connect(Droppable): Support for defaultProps will be removed from memo components')
    ) {
        return;
    }
    originalError.apply(console, args);
};

const PriorityPage = () => {
    const { tasks, saveTask, isLoading } = useTasks();
    const { settings } = useSettings();
    const [columns, setColumns] = useState({
        CRITICAL: { tasks: [], habits: [] },
        OPPORTUNITY_NOW: { tasks: [], habits: [] },
        OVER_THE_HORIZON: { tasks: [], habits: [] },
        PARKING_LOT: { tasks: [], habits: [] },
    });

    useEffect(() => {
        if (tasks) {
            const recurringTaskIds = new Set(
                tasks
                    .filter((task) => task.recurrenceRule && task.recurrenceRule.toUpperCase() !== 'ONCE')
                    .map((task) => task.id)
            );

            const filteredTasks = tasks.filter((task) => {
                const taskDate = parseISO(task.startDate);
                const today = startOfDay(new Date());

                if (isToday(taskDate)) {
                    return true; // Always include tasks for today
                }

                if (recurringTaskIds.has(task.id)) {
                    return true; // Always include recurring tasks
                }

                return settings.showFuture ? isFuture(taskDate) : taskDate <= today;
            });

            const newColumns = Object.keys(priorityConfig).reduce((acc, priority) => {
                acc[priority] = { tasks: [], habits: [] };
                return acc;
            }, {});

            filteredTasks.forEach((task) => {
                const column = newColumns[task.priority];
                if (column) {
                    if (recurringTaskIds.has(task.id)) {
                        // This is a parent recurring task (habit)
                        column.habits.push(task);
                    } else if (!task.parentTask) {
                        // This is a regular task (not a child of a recurring task)
                        column.tasks.push(task);
                    }
                }
            });

            setColumns(newColumns);
        }
    }, [tasks, settings.showFuture]);

    if (isLoading) {
        return <div>Loading tasks...</div>;
    }

    const priorityConfig = {
        CRITICAL: { color: '#0C803D', image: criticalImage, label: 'Critical' },
        OPPORTUNITY_NOW: { color: '#107CC4', image: opportunityNowImage, label: 'Opportunity Now' },
        OVER_THE_HORIZON: { color: '#F9913B', image: overTheHorizonImage, label: 'Over The Horizon' },
        PARKING_LOT: { color: '#F04F23', image: parkingLotImage, label: 'Parking Lot' },
    };

    const onDragEnd = (result) => {
        const { source, destination } = result;

        if (!destination) return;

        const sourceColumn = columns[source.droppableId.split('-')[0]];
        const destColumn = columns[destination.droppableId.split('-')[0]];
        const sourceList = source.droppableId.split('-')[1];
        const destList = destination.droppableId.split('-')[1];

        if (sourceColumn === destColumn && sourceList === destList) {
            const newList = Array.from(sourceColumn[sourceList]);
            const [reorderedItem] = newList.splice(source.index, 1);
            newList.splice(destination.index, 0, reorderedItem);

            const newColumn = {
                ...sourceColumn,
                [sourceList]: newList,
            };

            setColumns({
                ...columns,
                [source.droppableId.split('-')[0]]: newColumn,
            });
        } else {
            const sourceList = Array.from(sourceColumn[source.droppableId.split('-')[1]]);
            const destList = Array.from(destColumn[destination.droppableId.split('-')[1]]);
            const [movedItem] = sourceList.splice(source.index, 1);
            destList.splice(destination.index, 0, movedItem);

            const newColumns = {
                ...columns,
                [source.droppableId.split('-')[0]]: {
                    ...sourceColumn,
                    [source.droppableId.split('-')[1]]: sourceList,
                },
                [destination.droppableId.split('-')[0]]: {
                    ...destColumn,
                    [destination.droppableId.split('-')[1]]: destList,
                },
            };

            setColumns(newColumns);

            // Update task priority and save
            const updatedTask = { ...movedItem, priority: destination.droppableId.split('-')[0] };
            saveTask(updatedTask);
        }
    };

    return (
        <div className="priority-page-container">
            <div className="flex flex-col p-4 mx-auto max-w-7xl">
                <h1 className="mb-4 text-2xl font-bold">Priorities</h1>
                <DragDropContext onDragEnd={onDragEnd}>
                    <div className="flex flex-1 gap-4" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        {Object.entries(columns).map(([columnId, columnData]) => (
                            <div key={columnId} className="flex-1" style={{ maxWidth: '25%' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        mb: 2,
                                        maxWidth: '250px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    <Avatar
                                        alt={`${priorityConfig[columnId].label} Image`}
                                        src={priorityConfig[columnId].image}
                                        sx={{ width: 40, height: 40, marginRight: 2 }}
                                    />
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontWeight: 'bold',
                                            color: priorityConfig[columnId].color,
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        {priorityConfig[columnId].label}
                                    </Typography>
                                </Box>
                                {['habits', 'tasks'].map((listType) => (
                                    <div key={`${columnId}-${listType}`}>
                                        <h3 className="mb-2 font-semibold capitalize">{listType}</h3>
                                        <Droppable droppableId={`${columnId}-${listType}`} type={listType}>
                                            {(provided, snapshot = {}) => (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    className="bg-gray-100 p-2 rounded min-h-[100px] mb-4"
                                                >
                                                    {columnData[listType].map((item, index) => (
                                                        <Draggable key={item.id} draggableId={item.id} index={index}>
                                                            {(provided, snapshot = {}) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    className={`mb-2 bg-white rounded-lg shadow-sm ${
                                                                        snapshot.isDragging ? 'shadow-md' : ''
                                                                    }`}
                                                                    style={{
                                                                        ...provided.draggableProps.style,
                                                                        borderLeft: `4px solid ${priorityConfig[columnId].color}`,
                                                                    }}
                                                                >
                                                                    <TaskItem task={item} />
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </DragDropContext>
            </div>
        </div>
    );
};

export default PriorityPage;
