import React, { useState } from 'react';
import {
    Box,
    Grid,
    Paper,
    Typography,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Switch,
    FormControlLabel,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const planFeatures = {
    free: {
        name: 'Free Tier',
        price: 'Free',
        features: [
            { name: 'Connected Calendars', value: '2' },
            { name: 'Habits', value: '5' },
            { name: 'Autoscheduled Tasks', value: '10' },
            { name: 'Scheduling Range', value: '3 week' },
            { name: 'Early Access to New Features', value: false },
        ],
    },
    premium: {
        name: 'Premium',
        monthlyPrice: '$4.99',
        yearlyPrice: '$49.99',
        features: [
            { name: 'Connected Calendars', value: '5' },
            { name: 'Habits', value: 'Unlimited' },
            { name: 'Autoscheduled Tasks', value: 'Unlimited' },
            { name: 'Scheduling Range', value: '8 week' },
            { name: 'Early Access to New Features', value: true },
        ],
    },
};

const PlanColumn = ({ plan, isRecommended, isCurrentPlan, billingCycle, onSubscribe, onManageSubscription }) => {
    const price =
        plan.name === 'Premium' ? (billingCycle === 'monthly' ? plan.monthlyPrice : plan.yearlyPrice) : plan.price;
    const period = plan.name === 'Premium' ? (billingCycle === 'monthly' ? '/month' : '/year') : '';

    return (
        <Paper
            elevation={3}
            sx={{ height: '100%', display: 'flex', flexDirection: 'column', position: 'relative', overflow: 'visible' }}
        >
            {isRecommended && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '-10px',
                        right: '-10px',
                        backgroundColor: '#3f51b5',
                        color: 'white',
                        padding: '5px 10px',
                        fontSize: '0.9rem',
                        fontWeight: 'bold',
                        transform: 'rotate(15deg)',
                        zIndex: 1,
                    }}
                >
                    RECOMMENDED
                </Box>
            )}
            {isCurrentPlan && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '-10px',
                        left: '-10px',
                        backgroundColor: '#4caf50',
                        color: 'white',
                        padding: '5px 10px',
                        fontSize: '0.9rem',
                        fontWeight: 'bold',
                        zIndex: 1,
                    }}
                >
                    CURRENT PLAN
                </Box>
            )}
            <Box sx={{ p: 3, flexGrow: 1, border: isCurrentPlan ? '2px solid #4caf50' : 'none' }}>
                <Typography variant="h5" component="h2" gutterBottom fontWeight="bold">
                    {plan.name}
                </Typography>
                <Typography variant="h3" component="div" gutterBottom fontWeight="bold">
                    {price}
                    <Typography variant="h6" component="span" color="text.secondary">
                        {period}
                    </Typography>
                </Typography>
                {plan.name === 'Premium' && billingCycle === 'yearly' && (
                    <Typography variant="subtitle1" color="success.main" fontWeight="bold">
                        Save 16%!
                    </Typography>
                )}
                <TableContainer>
                    <Table>
                        <TableBody>
                            {plan.features.map((feature, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        {typeof feature.value === 'boolean' ? (
                                            feature.value ? (
                                                <CheckIcon color="primary" />
                                            ) : (
                                                <CloseIcon color="error" />
                                            )
                                        ) : (
                                            <CheckIcon color="primary" />
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">{feature.name}</Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography variant="body1">
                                            {typeof feature.value === 'boolean'
                                                ? feature.value
                                                    ? 'Yes'
                                                    : 'No'
                                                : feature.value}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Box sx={{ p: 2 }}>
                <Button
                    variant="contained"
                    color={isCurrentPlan ? 'success' : 'primary'}
                    fullWidth
                    size="large"
                    onClick={() => onSubscribe(plan.name, billingCycle)}
                >
                    {isCurrentPlan ? 'Current Plan' : `Select ${plan.name}`}
                </Button>
            </Box>
        </Paper>
    );
};

export const PricingTable = ({ currentPlan = 'free', onSubscribe, onManageSubscription }) => {
    const [billingCycle, setBillingCycle] = useState('monthly');

    const handleBillingCycleChange = (event) => {
        setBillingCycle(event.target.checked ? 'yearly' : 'monthly');
    };

    return (
        <Box sx={{ flexGrow: 1, py: 4 }}>
            <Typography variant="h3" component="h1" gutterBottom align="center" fontWeight="bold">
                Select a plan
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={billingCycle === 'yearly'}
                            onChange={handleBillingCycleChange}
                            color="primary"
                        />
                    }
                    label={
                        <Typography variant="h6">
                            {billingCycle === 'monthly' ? 'Monthly Billing' : 'Yearly Billing (Save 16%)'}
                        </Typography>
                    }
                />
            </Box>
            <Grid container spacing={4} justifyContent="center">
                <Grid item xs={12} sm={6} md={5}>
                    <PlanColumn
                        plan={planFeatures.free}
                        isCurrentPlan={currentPlan === 'free'}
                        billingCycle={billingCycle}
                        onSubscribe={onSubscribe}
                        onManageSubscription={onManageSubscription}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                    <PlanColumn
                        plan={planFeatures.premium}
                        isRecommended={true}
                        isCurrentPlan={currentPlan === 'premium'}
                        billingCycle={billingCycle}
                        onSubscribe={onSubscribe}
                        onManageSubscription={onManageSubscription}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default PricingTable;
