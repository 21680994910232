import { useGoogleLogin } from '@react-oauth/google';
import React, { useEffect, useState } from 'react';
import { GoogleLoginButton, MicrosoftLoginButton } from 'react-social-login-buttons';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt, faCalendarAlt, faClock, faChartBar, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import CalendarAccounts from './CalendarAccounts';
import { Capacitor } from '@capacitor/core';
import ReactGA from 'react-ga4';
import { useCustomer } from '../../providers/CustomerProvider';

import { useGoogleCalendarEvents } from '../tasks/hooks/useGoogleCalendarEvents';
import { useMicrosoftCalendarEvents } from '../tasks/hooks/useMicrosoftCalendarEvents';

import { useAtom } from 'jotai';
import { tokenAtom } from '../../atoms/tokenAtoms';
import { CalendarErrorModal } from './CalendarErrorModal';

export const CalendarSetup = ({ buttonSize }) => {
    const { customer, refetchCustomer } = useCustomer();
    const [token] = useAtom(tokenAtom);
    const [isMobile] = useState(Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'ios');
    const { handleMicrosoftAuth, connectMicrosoftAccount } = useMicrosoftCalendarEvents(customer);
    const { handleGoogleAuth } = useGoogleCalendarEvents(customer);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        ReactGA.initialize('G-HC3CNRJ6WD', {});
        ReactGA.send({ hitType: 'pageview', page: '/calendar', title: 'CalendarSetup' });
    }, []);

    const handleGoogleAuthResponse = async (codeResponse) => {
        if (codeResponse.error) {
            console.error(codeResponse.error);
            setErrorMessage('An error occurred during Google authentication. Please try again.');
            setErrorModalOpen(true);
            return;
        }

        try {
            const response = await handleGoogleAuth(codeResponse.code);
            console.log('Google auth response:', response);

            if (!response.ok) {
                if (response.status === 403 && response.data.error === 'insufficient_scope') {
                    const missingScopes = response.data.error_description.split(': ')[1];
                    setErrorMessage(`
                        ${response.data.message}
    
                        Missing permissions: ${missingScopes}
    
                        Please click "Try Again" and make sure to grant all requested permissions.
                    `);
                } else {
                    setErrorMessage(
                        response.data.message ||
                            'An error occurred while connecting to Google Calendar. Please try again.'
                    );
                }
                setErrorModalOpen(true);
            } else {
                refetchCustomer();
            }
        } catch (error) {
            console.error('Error during Google authentication:', error);
            setErrorMessage(
                'An unexpected error occurred while connecting to Google Calendar. Please try again later.'
            );
            setErrorModalOpen(true);
        }
    };
    const microsoftLogin = () => {
        console.log('Connecting Microsoft account');
        ReactGA.event({
            category: 'User',
            action: 'MicrosoftCalendar',
        });
        connectMicrosoftAccount();
        refetchCustomer();
    };

    const googleLogin = useGoogleLogin({
        onSuccess: (codeResponse) => handleGoogleAuthResponse(codeResponse),
        onFailure: (codeResponse) => console.log(codeResponse),
        scope: 'https://www.googleapis.com/auth/calendar.events.readonly https://www.googleapis.com/auth/calendar.calendarlist https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.events.readonly',
        uxMode: 'redirect',
        flow: 'auth-code',
        buttonText: 'Add Google account',
        accessType: 'offline',
        redirectUri: import.meta.env.VITE_PUBLIC_GOOGLE_REDIRECT_URL,
        clientId: import.meta.env.VITE_PUBLIC_GOOGLE_CLIENT_ID,
    });

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1,
            },
        },
    };

    const itemVariants = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                type: 'spring',
                stiffness: 50,
            },
        },
    };

    if (!token || !customer) {
        return <div>No authenticated customer</div>;
    }

    return (
        <div className="space-y-6">
            {!isMobile && (
                <motion.div
                    className="p-6 bg-gradient-to-br from-blue-100 to-blue-200 rounded-lg shadow-md"
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                >
                    <div className="flex justify-between items-start">
                        <motion.div variants={itemVariants} className="flex-grow">
                            <h1 className="mb-4 text-3xl font-bold text-blue-800">
                                <FontAwesomeIcon icon={faBolt} className="mr-2 text-yellow-500" />
                                Supercharge Your Productivity!
                            </h1>
                            <p className="mb-4 text-lg text-blue-700">
                                Connect your calendars and unlock the full potential of Mind Your Now.
                            </p>
                            <ul className="space-y-2 text-blue-600">
                                <motion.li variants={itemVariants} className="flex items-center">
                                    <FontAwesomeIcon icon={faCalendarAlt} className="mr-2 text-green-500" />
                                    Seamlessly integrate your Google and Microsoft calendars
                                </motion.li>
                                <motion.li variants={itemVariants} className="flex items-center">
                                    <FontAwesomeIcon icon={faClock} className="mr-2 text-purple-500" />
                                    Auto-schedule tasks around your existing commitments
                                </motion.li>
                                <motion.li variants={itemVariants} className="flex items-center">
                                    <FontAwesomeIcon icon={faChartBar} className="mr-2 text-orange-500" />
                                    Get a holistic view of your day, week, and month
                                </motion.li>
                            </ul>
                        </motion.div>
                        <motion.div variants={itemVariants} className="flex flex-col ml-6 space-y-4">
                            <GoogleLoginButton
                                onClick={() => {
                                    ReactGA.event({
                                        category: 'User',
                                        action: 'Google Calendar',
                                    });
                                    googleLogin();
                                }}
                                className="w-full"
                                aria-label="Connect Google Calendar"
                            >
                                <span>Connect Google Calendar</span>
                            </GoogleLoginButton>
                            <MicrosoftLoginButton
                                onClick={() => microsoftLogin()}
                                className="w-full"
                                aria-label="Connect Microsoft Calendar"
                            >
                                <span>Connect Microsoft Calendar</span>
                            </MicrosoftLoginButton>
                        </motion.div>
                    </div>
                    <motion.p variants={itemVariants} className="mt-4 text-sm text-blue-600">
                        <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
                        Don't worry, you can always manage or disconnect your accounts later.
                    </motion.p>
                </motion.div>
            )}
            <CalendarErrorModal
                isOpen={errorModalOpen}
                onClose={() => setErrorModalOpen(false)}
                onRetry={() => {
                    setErrorModalOpen(false);
                    googleLogin();
                }}
            />
            <CalendarAccounts
                buttonSize={buttonSize}
                accounts={customer.accounts}
                microsoftLogin={microsoftLogin}
                googleLogin={googleLogin}
            />
        </div>
    );
};
export default CalendarSetup;
