import { useCallback } from 'react';
import axios from 'axios';
import { subDays, addDays, startOfDay, endOfDay, format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { useAtom } from 'jotai';
import { tokenAtom } from '../../../atoms/tokenAtoms';

const googleColorConstants = {
    undefined: '#039be5',
    1: '#7986cb',
    2: '#33b679',
    3: '#8e24aa',
    4: '#e67c73',
    5: '#f6c026',
    6: '#f5511d',
    7: '#039be5',
    8: '#616161',
    9: '#3f51b5',
    10: '#0b8043',
    11: '#d60000',
};

export const useGoogleCalendarEvents = (customer) => {
    const baseUrl = import.meta.env.VITE_PUBLIC_API_HOST + '/';
    const [token] = useAtom(tokenAtom);

    const fetchGoogleCalendars = useCallback(async (token) => {
        try {
            const { data } = await axios.get('https://www.googleapis.com/calendar/v3/users/me/calendarList', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return data;
        } catch (error) {
            console.error('Error fetching Google calendars:', error.response?.data || error.message);
            return null;
        }
    }, []);

    const fetchGoogleEvents = useCallback(
        async (currentCalendarList) => {
            const defaultTimeZone = customer.defaultTimeZone;
            const now = new Date();
            const minDate = startOfDay(subDays(now, 7));
            const maxDate = endOfDay(addDays(now, 90));

            const googleCalendars = currentCalendarList.filter((calendar) => {
                const account = customer.accounts.find((account) =>
                    account.calendars.some((accCal) => accCal.id === calendar.id)
                );
                const accountCalendar = account ? account.calendars.find((accCal) => accCal.id === calendar.id) : null;
                const isUsing = accountCalendar ? accountCalendar.using : calendar.using;

                if (calendar.account.type !== 'google') {
                    return false;
                }
                if (calendar.account.isDirty) {
                    return false;
                }
                if (calendar.id.indexOf('holiday') !== -1) {
                    return false;
                }
                if (!isUsing) {
                    return false;
                }
                return true;
            });

            const allEvents = await Promise.all(
                googleCalendars.map(async (calendar) => {
                    try {
                        const response = await axios.get(
                            `https://www.googleapis.com/calendar/v3/calendars/${calendar.id}/events`,
                            {
                                params: {
                                    timeMin: formatInTimeZone(minDate, defaultTimeZone, "yyyy-MM-dd'T'HH:mm:ssXXX"),
                                    timeMax: formatInTimeZone(maxDate, defaultTimeZone, "yyyy-MM-dd'T'HH:mm:ssXXX"),
                                    maxResults: 1000,
                                    singleEvents: true,
                                    orderBy: 'startTime',
                                },
                                headers: {
                                    Authorization: `Bearer ${calendar.account.accessToken}`,
                                    'Content-Type': 'application/json',
                                },
                            }
                        );

                        return response.data.items.map((event) => ({
                            id: event.id,
                            calendarId: calendar.id,
                            htmlLink: event.htmlLink,
                            title: event.summary,
                            start: event.start.dateTime || event.start.date,
                            end: event.end.dateTime || event.end.date,
                            allDay: !event.start.dateTime,
                            color: calendar.backgroundColor || googleColorConstants[event.colorId],
                            accessToken: calendar.account.accessToken,
                            status: event.transparency === 'transparent' ? 'free' : 'busy',
                            textColor: 'white',
                        }));
                    } catch (error) {
                        console.error(
                            `Error fetching events for Google calendar ${calendar.id}:`,
                            error.response?.data || error.message
                        );
                        return [];
                    }
                })
            );

            const flattenedEvents = allEvents.flat();
            console.log(`Total Google events fetched: ${flattenedEvents.length}`);
            return flattenedEvents;
        },
        [customer, googleColorConstants]
    );

    const updateGoogleEvent = useCallback(async (calendarId, eventId, accessToken, updatedEvent) => {
        console.log(`Updating event in calendar ${calendarId}`);
        console.log('Updated event data:', updatedEvent);
        try {
            const response = await axios.patch(
                `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events/${eventId}`,
                updatedEvent,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            console.log(`Successfully updated event: ${response.data.id}`);
            return response.data;
        } catch (error) {
            console.error('Error updating Google event:', error.response?.data || error.message);
            throw error;
        }
    }, []);

    const handleGoogleAuth = useCallback(
        async (authCode) => {
            console.log('Sending google auth code to ' + baseUrl + 'oauth/googleauth');

            try {
                const response = await fetch(baseUrl + 'oauth/googleauth', {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                    body: JSON.stringify(authCode),
                });

                console.log('Server responded with status', response.status);

                const responseData = await response.text();
                console.log('Server responded with body', responseData);

                let parsedData;
                try {
                    parsedData = JSON.parse(responseData);
                } catch (e) {
                    console.error('Error parsing JSON response:', e);
                    parsedData = null;
                }

                if (!response.ok) {
                    return {
                        ok: false,
                        status: response.status,
                        data: parsedData || { message: 'An unexpected error occurred' },
                    };
                }

                console.log('Google auth response:', parsedData);
                return {
                    ok: true,
                    status: response.status,
                    data: parsedData,
                };
            } catch (err) {
                console.error('Error during Google authentication:', err);
                return {
                    ok: false,
                    status: 500,
                    data: { message: 'An unexpected error occurred during authentication' },
                };
            }
        },
        [baseUrl, token]
    );

    return { fetchGoogleCalendars, fetchGoogleEvents, updateGoogleEvent, handleGoogleAuth };
};

export { googleColorConstants };
