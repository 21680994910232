import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faCheckCircle, faClock, faChartLine } from '@fortawesome/free-solid-svg-icons';

const CalendarLinkBanner = () => {
    return (
        <div className="overflow-hidden mb-6 bg-gradient-to-r from-blue-100 to-blue-200 rounded-lg shadow-md">
            <div className="relative p-6">
                <div className="pr-32">
                    {' '}
                    {/* Add padding-right to make space for the button */}
                    <h2 className="mb-2 text-2xl font-bold text-blue-800">
                        <FontAwesomeIcon icon={faCalendarAlt} className="mr-2 text-blue-600" />
                        Supercharge your habits!
                    </h2>
                    <p className="mb-4 text-blue-700">
                        Link your calendar to automatically schedule your habits and boost your productivity.
                    </p>
                    <ul className="space-y-2">
                        {[
                            { icon: faCheckCircle, text: 'Never miss a habit again' },
                            { icon: faClock, text: 'Seamlessly integrate with your existing schedule' },
                            { icon: faChartLine, text: 'Track your progress effortlessly' },
                        ].map((item, index) => (
                            <li key={index} className="flex items-center text-blue-600">
                                <FontAwesomeIcon icon={item.icon} className="mr-2" />
                                {item.text}
                            </li>
                        ))}
                    </ul>
                </div>
                <Link
                    to="/settings"
                    className="inline-block absolute right-6 bottom-6 px-6 py-3 font-semibold text-white bg-blue-600 rounded-full transition-colors duration-200 hover:bg-blue-700"
                >
                    Link Your Calendar Now
                </Link>
            </div>
        </div>
    );
};

export default CalendarLinkBanner;
