import { useCallback } from 'react';
import axios from 'axios';
import { subDays, addDays, startOfDay, endOfDay, differenceInHours, isSameDay } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { useAtom } from 'jotai';
import { tokenAtom } from '../../../atoms/tokenAtoms';
import moment from 'moment-timezone';

export const useMicrosoftCalendarEvents = (customer) => {
    const baseUrl = import.meta.env.VITE_PUBLIC_API_HOST + '/';
    const [token] = useAtom(tokenAtom);

    const fetchMicrosoftCalendars = useCallback(async (accessToken) => {
        try {
            const { data } = await axios.get('https://graph.microsoft.com/v1.0/me/calendars', {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            return data.value.map((calendar) => ({
                ...calendar,
                account: { type: 'microsoft', accessToken },
            }));
        } catch (error) {
            console.error('Error fetching Microsoft calendars:', error.response?.data || error.message);
            return [];
        }
    }, []);

    const fetchMicrosoftEvents = useCallback(
        async (currentCalendarList) => {
            const defaultTimeZone = customer.defaultTimeZone;
            const now = new Date();
            const minDate = startOfDay(subDays(now, 7));
            const maxDate = endOfDay(addDays(now, 30));

            const microsoftCalendars = currentCalendarList.filter((calendar) => calendar.account.type === 'microsoft');

            const allEvents = await Promise.all(
                microsoftCalendars.map(async (calendar) => {
                    try {
                        const response = await axios.get(
                            `https://graph.microsoft.com/v1.0/me/calendars/${calendar.id}/calendarView`,
                            {
                                params: {
                                    startDateTime: formatInTimeZone(minDate, defaultTimeZone, "yyyy-MM-dd'T'HH:mm:ss"),
                                    endDateTime: formatInTimeZone(maxDate, defaultTimeZone, "yyyy-MM-dd'T'HH:mm:ss"),
                                    $select: 'id,subject,start,end,showAs',
                                    $top: 1000,
                                },
                                headers: {
                                    Authorization: `Bearer ${calendar.account.accessToken}`,
                                    'Content-Type': 'application/json',
                                },
                            }
                        );

                        return response.data.value.map((event) => {
                            const start = moment.tz(event.start.dateTime, event.start.timeZone).toDate();
                            const end = moment.tz(event.end.dateTime, event.end.timeZone).toDate();

                            const isAllDay = differenceInHours(end, start) >= 24 && isSameDay(start, end);

                            return {
                                id: event.id,
                                calendarId: calendar.id,
                                htmlLink: '',
                                title: event.subject,
                                start: start.toISOString(),
                                end: end.toISOString(),
                                allDay: isAllDay,
                                color: '',
                                accessToken: calendar.account.accessToken,
                                showAs: event.showAs,
                                backgroundColor: event.showAs === 'free' || event.showAs === 'tentative' ? 'white' : '',
                                textColor: event.showAs === 'free' || event.showAs === 'tentative' ? 'black' : 'white',
                                borderColor: '#039be5',
                            };
                        });
                    } catch (error) {
                        console.error(`Error fetching events for Microsoft calendar ${calendar.id}:`, error);
                        return [];
                    }
                })
            );

            const flattenedEvents = allEvents.flat();
            console.log(`Total Microsoft events fetched: ${flattenedEvents.length}`);
            return flattenedEvents;
        },
        [customer]
    );
    const handleMicrosoftAuth = useCallback(
        async (accessToken) => {
            console.log('Sending microsoftauth request to ' + baseUrl + 'oauth/microsoftauth');
            try {
                const response = await fetch(baseUrl + 'oauth/microsoftauth', {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                    body: JSON.stringify(accessToken),
                });

                const data = await response.json();
                return data;
            } catch (err) {
                console.error('Error during Microsoft authentication:', err);
                return null;
            }
        },
        [baseUrl, token]
    );
    const connectMicrosoftAccount = useCallback(async () => {
        console.log('Connecting Microsoft account');
        try {
            const url = `${baseUrl}microsoft/connect`;
            const options = 'height=600,width=800';
            window.open(url, 'Microsoft Connect', options);
        } catch (err) {
            console.error('Error connecting Microsoft account:', err);
        }
    }, [baseUrl]);

    const fetchMicrosoftCode = useCallback(
        async (code) => {
            try {
                const response = await fetch(baseUrl + 'microsoft/mscode', {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ code }),
                });

                const answer = await response.json();
                return answer;
            } catch (err) {
                console.error('Error fetching Microsoft code:', err);
                return null;
            }
        },
        [baseUrl, token]
    );

    return {
        fetchMicrosoftCalendars,
        fetchMicrosoftEvents,
        handleMicrosoftAuth,
        connectMicrosoftAccount,
        fetchMicrosoftCode,
    };
};
